import {
  SimpleGrid,
  Text,
  useColorModeValue,
  useBreakpointValue,
  Button,
  Flex,
  Box,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import Information from './Information';
import { formatCurrency } from '../../../../lib/utilities';
import { CompanyDto } from '../../../../generated-client/model/company-dto';
import CountryEnum = CompanyDto.CountryEnum;
import { useNavigate } from 'react-router-dom';
import PaymentRequests from 'views/dashboard/myPaymentRequests/components/PaymentRequest';
import { ProductDto } from 'generated-client/model/product-dto';
// import FeaturedProducts from "./FeaturedProducts";

interface GeneralInformationProps {
  companyName: string;
  companyAddress: string;
  creditLimit: number;
  amountDue: number;
  exchangeRate: string;
  dueDate: string;
  companyCountry: CountryEnum;
  [x: string]: any;
  isDue: boolean;
  featuredProducts: ProductDto[];
}

export default function GeneralInformation(props: GeneralInformationProps) {
  const {
    companyName,
    companyAddress,
    creditLimit,
    exchangeRate,
    amountDue,
    dueDate,
    companyCountry,
    isDue,
    featuredProducts,
    ...rest
  } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 4 }); // Responsive columns based on the screen size
  const navigate = useNavigate();
  const bg = useColorModeValue('white', 'navy.700');
  const isTableLayout = useBreakpointValue({ base: false, md: true });

  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  const handleOrderProduct = (product: ProductDto) => {
    localStorage.setItem('featuredProduct', JSON.stringify(product));
    navigate('/dashboard/paymentRequest');
  };

  return (
    <Card
      mb={{ base: '0px', '2xl': '20px' }}
      px={'20px'}
      pt='30px'
      pb='110px'
      {...rest}
    >
      <Text
        fontSize='xl'
        fontWeight='medium'
        color={textColorPrimary}
        mb='16px'
      >
        {companyName}
      </Text>
      <SimpleGrid columns={columns} gap='20px' mb={10} alignItems={'start'}>
        {/* <Information
          boxShadow={cardShadow}
          data={{
            title: 'Your Credit Limits',
            value:
              //(companyCountry === CountryEnum.Kenya ? "KES " : "RWF ") +
              'ZAR ' + formatCurrency(parseFloat(creditLimit.toString())),
          }}
        /> */}
        <Information
          boxShadow={cardShadow}
          data={[
            {
              title: 'Amount Due',
              value:
                //(companyCountry === CountryEnum.Kenya ? 'KES ' : 'RWF ') +
                'R ' + formatCurrency(parseFloat(amountDue.toString())),
            },
            // { title: 'Due Date', value: dueDate },
            { title: '', value: '' },
          ]}
          textValueColorPrimary={amountDue > 0 && isDue ? 'red.500' : undefined}
        />
      </SimpleGrid>

      {!isTableLayout && (
        <Button
          colorScheme='blue'
          borderRadius={4}
          mb={10}
          onClick={() => navigate('/dashboard/paymentRequest')}
        >
          New order
        </Button>
      )}

      <Flex align='center' mb={5}>
        <Text fontSize='lg' color={textColorPrimary}>
          Recent orders
        </Text>

        {isTableLayout && (
          <Button
            colorScheme='blue'
            borderRadius={4}
            onClick={() => navigate('/dashboard/paymentRequest')}
            marginLeft='auto'
          >
            New order
          </Button>
        )}
      </Flex>

      <Card bg={bg} boxShadow={cardShadow}>
        <Box>
          <PaymentRequests isHomeScreen={true} />
        </Box>
      </Card>

      {/* <Text fontSize="lg" color={textColorPrimary} mt={10}>
        Featured products
      </Text>

      <FeaturedProducts
        featuredProducts={featuredProducts}
        handleOrderProduct={handleOrderProduct}
      /> */}
    </Card>
  );
}
