import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './assets/css/App.css';
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import DashboardLayout from './layouts/dashboard';
import AdminLayout from './layouts/admin';
import { provider } from '@/components/ui/provider';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import './translations/i18n';
import 'flag-icon-css/css/flag-icons.css';
import * as Sentry from '@sentry/react';
import UploadPage from 'views/upload';

function ProtectedRoute() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  if (isLoading) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  function redirectUser() {
    if (isAuthenticated) {
      Sentry.captureEvent({
        message:
          'A User is authenticated' + isAuthenticated + ' ' + user?.email,
      });
      navigate('/dashboard/default');
    } else {
      Sentry.captureEvent({
        message:
          'A User is not authenticated' + isAuthenticated + ' ' + user?.email,
      });
      navigate('/auth/sign-in');
    }
  }
  redirectUser();
}

Sentry.init({
  dsn: 'https://d58b78305f94c0996d71339d2ecf1812@o4507965199941632.ingest.de.sentry.io/4507965202759760',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <Auth0Provider
      domain='auth.tradeflow.finance'
      clientId='wpIgQxBvPNwSs4b0JhC2FamwtWvpdrgf'
      authorizationParams={{
        redirect_uri: window.location.origin + '/dashboard/default',
        scope: 'openid profile email phone',
        audience: 'https://tradeflow.finance',
      }}
      cacheLocation='localstorage'
    >
      <React.StrictMode>
        <HashRouter>
          <Routes>
            <Route path='/auth/*' element={<AuthLayout />} />
            <Route path='/upload' element={<UploadPage />} />
            <Route path='/dashboard/*' element={<DashboardLayout />} />
            <Route path='/admin/*' element={<AdminLayout />} />
            <Route path='/' element={<ProtectedRoute />} />
          </Routes>
        </HashRouter>
      </React.StrictMode>
    </Auth0Provider>
  </ChakraProvider>
);
