import { useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Invoice } from '../index.types';
import { getStatusFromDate } from '../utils';
import { exportToExcel } from 'services/invoices';

const StyledStatus = styled(Text)<{ status: string }>`
  color: ${(props) => {
    switch (props.status) {
      case 'OVERDUE':
        return 'red';
      case 'DUE TODAY':
        return 'orange';
      case 'OUTSTANDING':
        return 'brown';
      case 'PAID':
        return 'green';
      default:
        return 'black';
    }
  }};
`;

export interface InvoicesTableProps {
  invoices: Array<Invoice>;
  setSelectedInvoice: (invoice: Invoice) => void;
  accessToken: string;
}

export const InvoicesTable = (props: InvoicesTableProps) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [filter, setFilter] = useState<
    'all' | 'open' | 'dueToday' | 'paid' | 'overdue'
  >('all');

  const handleSelectRow = (id: string) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSelectAllRows = () => {
    if (selectedRows.length === props.invoices.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(props.invoices.map((row) => row.id));
    }
  };

  const visibleInvoices = props.invoices.filter((invoice) => {
    if (filter === 'all') {
      return true;
    }
    if (filter === 'open') {
      return invoice.status !== 'PAID';
    }
    if (filter === 'dueToday') {
      return getStatusFromDate(invoice.dueDate, invoice.status) === 'DUE TODAY';
    }
    if (filter === 'paid') {
      return invoice.status === 'PAID';
    }
    if (filter === 'overdue') {
      return getStatusFromDate(invoice.dueDate, invoice.status) === 'OVERDUE';
    }
    return false;
  });

  function exportTo() {
    exportToExcel(props.accessToken);
  }

  return (
    <>
      <Flex direction={'column'} gap={'1rem'}>
        <Flex direction={'row'} gap={'1rem'} justify={'space-between'}>
          <Flex gap={'2rem'}>
            <Button
              variant={filter === 'all' ? 'outline' : 'ghost'}
              onClick={() => setFilter('all')}
            >
              All
            </Button>
            <Button
              variant={filter === 'open' ? 'outline' : 'ghost'}
              onClick={() => setFilter('open')}
            >
              Open
            </Button>
            <Button
              variant={filter === 'dueToday' ? 'outline' : 'ghost'}
              onClick={() => setFilter('dueToday')}
            >
              Due Today
            </Button>
            <Button
              variant={filter === 'paid' ? 'outline' : 'ghost'}
              onClick={() => setFilter('paid')}
            >
              Paid
            </Button>
            <Button
              variant={filter === 'overdue' ? 'outline' : 'ghost'}
              onClick={() => setFilter('overdue')}
            >
              Overdue
            </Button>
          </Flex>
          <Button variant={'outline'} bg={'palegreen'} onClick={exportTo}>
            Export to Excel
          </Button>
        </Flex>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isChecked={selectedRows.length === props.invoices.length}
                    onChange={handleSelectAllRows}
                    size='lg'
                  ></Checkbox>
                </Th>
                <Th>Supplier</Th>
                <Th>Invoice number</Th>
                <Th>Amount</Th>
                <Th>Issue date</Th>
                <Th>Due date</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {visibleInvoices.map((row) => (
                <Tr key={row.id} onClick={() => props.setSelectedInvoice(row)}>
                  <Td>
                    <Checkbox
                      isChecked={selectedRows.includes(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    >
                      {' '}
                    </Checkbox>
                  </Td>
                  <Td>
                    <Tooltip label={row.supplierName} aria-label='supplier'>
                      {row.supplierName.substring(0, 20)}
                    </Tooltip>
                  </Td>
                  <Td>{row.supplierInvoiceId}</Td>
                  <Td>{row.amount}</Td>
                  <Td>{dayjs(row.issueDate).format('MMM-DD')}</Td>
                  <Td>{dayjs(row.dueDate).format('MMM-DD')}</Td>
                  <Td>
                    <StyledStatus
                      status={getStatusFromDate(row.dueDate, row.status)}
                    >
                      {getStatusFromDate(row.dueDate, row.status)}
                    </StyledStatus>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  );
};
