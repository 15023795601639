import saveAs from 'file-saver';
import { callGet, callPatch, callPost } from 'lib/api-private';
import { saveProps } from 'views/admin/pendingInvoices/components/SelectedInvoiceEditModal';

export const getAllUnprocessedInvoices = async (accessToken: string) => {
  const response = await callGet(accessToken, '/invoices/unprocessed');
  return response;
};

export const getAllUserInvoices = async (accessToken: string) => {
  const response = await callGet(accessToken, '/invoices');
  return response;
};

export const updateInvoiceStatus = async (
  accessToken: string,
  invoiceId: string,
  status: string
) => {
  const response = await callPatch(accessToken, `/invoices/${invoiceId}`, {
    status,
  });
  return response;
};

export const createInvoice = async (accessToken: string, props: saveProps) => {
  const response = await callPost(accessToken, '/invoices', props);
  return response;
};

export const exportToExcel = async (accessToken: string) => {
  const response = await fetch(
    process.env.REACT_APP_BACKEND_URL + '/invoices/export',
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  console.log('response');
  const blob = await response.blob();

  const contentDisposition = response.headers.get('Content-Disposition');
  console.log('filename', contentDisposition);
  console.log('response', response.headers);
  const filename = contentDisposition
    ? contentDisposition.split('filename=')[1]
    : 'invoices.xlsx';
  saveAs(blob, filename);
  // return response;
};
