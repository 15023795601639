import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatCurrency } from 'lib/utilities';

const SummaryBox = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 2px solid #e2e8f0;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  width: 30%;
`;

const SummaryBoxTitle = styled(Text)<{ warning?: boolean }>`
  color: ${(props) => (props.warning ? 'red' : 'black')};
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const SummaryBoxSubTitle = styled(Text)`
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
`;

const SummaryBoxText = styled(Text)`
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: gray;
`;

const StyledButton = styled(Button)`
  background-color: #3182ce;
  color: white;
  &:hover {
    background-color: #2b6cb0;
  }
`;

export interface SummaryComponentProps {
  total: {
    numInvoices: number;
    amount: number;
  };
  overdue: {
    numInvoices: number;
    amount: number;
  };
  dueToday: {
    numInvoices: number;
    amount: number;
  };
}

export const SummaryComponent = (props: SummaryComponentProps) => {
  return (
    <>
      <Flex justifyContent={'space-between'}>
        <SummaryBox>
          <SummaryBoxTitle>
            R {formatCurrency(parseFloat(props.total.amount.toString()))}
          </SummaryBoxTitle>
          <SummaryBoxSubTitle>Total Open</SummaryBoxSubTitle>
          <SummaryBoxText>{props.total.numInvoices} invoices</SummaryBoxText>
        </SummaryBox>

        <SummaryBox>
          <SummaryBoxTitle>
            R {formatCurrency(parseFloat(props.dueToday.amount.toString()))}
          </SummaryBoxTitle>
          <SummaryBoxSubTitle>Due today</SummaryBoxSubTitle>
          <SummaryBoxText>{props.dueToday.numInvoices} invoices</SummaryBoxText>
        </SummaryBox>

        <SummaryBox>
          <SummaryBoxTitle warning={props.overdue.amount > 0}>
            R {formatCurrency(parseFloat(props.overdue.amount.toString()))}
          </SummaryBoxTitle>
          <SummaryBoxSubTitle>Total Overdue</SummaryBoxSubTitle>
          <SummaryBoxText>{props.overdue.numInvoices} invoices</SummaryBoxText>
        </SummaryBox>
      </Flex>
    </>
  );
};
